import React from 'react';
import PropTypes from 'utils/propTypes';

import classNames from 'classnames';

import userImage from 'assets/img/users/avatar-small.png';

const Avatar = ({
  rounded=false,
  circle=true,
  src=userImage,
  size=40,
  className,
  style={},
  ...restProps
}) => {
  const classes = classNames({ 'rounded-circle': circle, rounded }, className);
  return (
    <img
      src={src || userImage}
      style={{ width: size, height: size, ...style }}
      className={classes}
      {...restProps}
    />
  );
};

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  style: PropTypes.object,
};

export default Avatar;
