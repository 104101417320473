import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import breakpoint from '../../../utils/breakpoint';
import { openSidebar, isSidebarOpen } from '../../../utils/sidebar';

const Layout = function ({ children }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const current = useMemo(() => breakpoint(width), [width]);

  const handleContentClick = useCallback(() => {
    if (isSidebarOpen() && (current === 'xs' || current === 'sm' || current === 'md')) {
      openSidebar('close');
    }
  }, [current]);

  useEffect(() => {
    if (['xs', 'sm', 'md'].includes(current)) {
      openSidebar('close');
    } else {
      openSidebar('open');
    }
  }, [current]);

  return (
    <main className="cr-app bg-light">
      <Sidebar />
      <Content fluid onClick={handleContentClick}>
        <Header />
        {children}
        <Footer />
      </Content>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
