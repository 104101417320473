import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import bn from 'utils/bemnames';
import { Tab, Tabs, Box } from '@mui/material';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { useHistory } from 'react-router-dom';
import ProjectDetailsForm from '../features/shared/components/ProjectDetailsForm';
import SendNotification from './SendNotification';
import NotificationSettings from './NotificationSettings';
import AccountHealthSettings from './AccountHealthSettings';
import {
  TASK_PUT,
  MILESTONE_PUT,
  PROJECT_PUT,
  ACCOUNT_UPDATE,
  NOTIFICATIONS_GET,
  PROJECT_CREATE
} from '../utils/utils';
import { selectToken, resetToken } from '../features/auth/authSlice';
import { selectCurrentProject } from '../features/project/projectSlice';
import { useSetSettingMutation } from '../features/me/meApi';
import { postData, getData, putData } from '../utils/requests';
import { displayError, ERRORS } from '../features/utils/messages';

const bem = bn.create('modal-customer-details');

const initialDetail = {
  serviceTradeTier: '',
  licenseCount: '',
  salesforceId: '',
  asanaId: '',
  subsriptionStart: '',
  subsriptionEnd: '',
  goLiveDate: '',
  project_disabled: false,
  project_archived: false,
};

const tabTypes = {
  Send_Notification: 'Send_Notification',
  Notification_Settings: 'Notification_Settings',
  Account_Edit: 'Account_Edit',
  Account_Health: 'Account_Health',
};

const formatNotification = (notification, notificationType, currentCustomer, notifEnabled) => {
  const { frequency, date, subscribers: currentSubscribers } = notification.body;
  const { email: projectManagerEmail } = currentCustomer.asanaData.project.owner;
  const { sponsorEmailSummary: newEmail } = notification;
  let emailBody = currentSubscribers;
  let ownerEmails = [projectManagerEmail];

  if (currentCustomer.asanaData.project.project_roles && currentCustomer.asanaData.project.project_roles.length > 0) {
    const index = currentCustomer.asanaData.project.project_roles.findIndex(role => role.name.toLowerCase() === "project coordinator");
    if (index > -1) {
      const projectCoordinatorEmail = currentCustomer.asanaData.project.project_roles[index].email;
      ownerEmails = [projectManagerEmail, projectCoordinatorEmail];
    }
  }

  if (!notificationType || !notification || !frequency || !date) return {};

  if (newEmail.length > 3) {
    emailBody = [...currentSubscribers, newEmail];
  }

  return {
    notificationType,
    frequency,
    startDate: date,
    newSponsorEmail: emailBody,
    onboardingTeamEmails: ownerEmails,
    notificationEnabled: notifEnabled,
  };
};

const notificationTypes = {
  overdueTask: 'overdueTask',
  summaryDigest: 'summaryDigest',
};

const ModalCustomerDetails = function (props) {
  const { toggleDetails, isBlank } = props;
  const token = useSelector(selectToken);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [details, setDetails] = useState(initialDetail);
  const [accounts, setAccounts] = useState([]);
  const [shouldCreateDemoAccount, setCreateDemoAccount] = useState(false);
  const [setSetting] = useSetSettingMutation();

  const [value, setValue] = useState(tabTypes.Account_Edit);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const currentCustomer = useSelector(selectCurrentProject);
  const { projectId } = currentCustomer || { projectId: 0 };
  const [summaryDigest_notification_active, setSummaryNotification] = useState(false);
  const [overdueTask_notification_active, setOverdueNotification] = useState(false);
  const [summaryDigestNotification, setSummaryDigestNotification] = useState({
    body: {
      frequency: '',
      date: '',
      subscribers: [],
    },
    sponsorEmailSummary: '',
    listOfSummaryEmails: [],
  });

  const [overdueTaskNotification, setOverdueTaskNotification] = useState({
    body: {
      frequency: '',
      date: '',
      subscribers: [],
    },
    sponsorEmailSummary: '',
    listOfSummaryEmails: [],
  });

  async function getNotifications() {
    setNotificationLoading(true);
    const resultNotifications = await getData(`${NOTIFICATIONS_GET}/${projectId}`, token);

    if (resultNotifications.data && resultNotifications.data.length > 0) {
      const summaryDigestNotif = resultNotifications.data.find(
        notif => notif.notificationType === 'summaryDigest',
      );
      if (summaryDigestNotif) {
        setSummaryDigestNotification({
          ...summaryDigestNotification,
          body: {
            frequency: summaryDigestNotif.frequency,
            date: summaryDigestNotif.startDate,
            subscribers: summaryDigestNotif.newSponsorEmail,
          },
        });
        setSummaryNotification(summaryDigestNotif.isActive);
      }

      const overdueTaskNotif = resultNotifications.data.find(
        notif => notif.notificationType === 'overdueTask',
      );
      if (overdueTaskNotif) {
        setOverdueTaskNotification({
          ...overdueTaskNotification,
          body: {
            frequency: overdueTaskNotif.frequency,
            date: overdueTaskNotif.startDate,
            subscribers: overdueTaskNotif.newSponsorEmail,
          },
        });
        setOverdueNotification(overdueTaskNotif.isActive);
      }
    }
    setNotificationLoading(false);
  }

  useEffect(() => {
    if (currentCustomer && !isBlank) {
      const {
        subscription,
        subscription_end_date,
        subscription_start_date,
        go_live_date,
        licenses,
        salesforceNotes,
        serviceTradeAccounts,
        projectId,
        project_disabled,
        asanaData,
      } = currentCustomer;

      getNotifications();

      setDetails({
        serviceTradeTier: subscription,
        licenseCount: licenses,
        salesforceId: salesforceNotes ? salesforceNotes['Account ID'] : 0,
        asanaId: projectId,
        subsriptionStart: subscription_start_date,
        subsriptionEnd: subscription_end_date,
        goLiveDate: go_live_date,
        project_disabled: project_disabled,
        project_archived: asanaData.project.project_archived,
      });
      if (serviceTradeAccounts) {
        setAccounts(
          serviceTradeAccounts.map(item => {
            return {
              accountId: item.accountId,
              name: item.name,
              type: `${item.type.toUpperCase()}`,
            };
          }),
        );
      }
    }
  }, [currentCustomer, isBlank]);

  const headerClass = bem.b('border-0');
  const footerGroup = bem.b('border-0');
  const spinnerClass = bem.b('row-spinner');

  const handleChange = e => {
    const val = e.target.value;
    setDetails({
      ...details,
      [e.target.name]: val,
    });
  };

  const createProject = projectId => {
    if (projectId) {
      setLoading(true);
      const headerObject = { 
        "Content-Type": "application/json",
      }
      postData(`${PROJECT_CREATE}/${projectId}`, {
        shouldCreateDemoAccount: shouldCreateDemoAccount
      }, token)
        .then(response => {
          if (response.error && response.statusCode === 401) {
            resetToken();
          }
          return response;
        })
        .then(response => {
          if (response.error) {
            throw new Error(response.message);
          }

          return response.data;
        })
        .then((newProject) => {
          // creating tasks
          const { asanaData } = newProject;
          const { tasks, projectMileStone} = asanaData;
          tasks.forEach(async (task) => {
            await putData(`${TASK_PUT}/${projectId}`, { task }, headerObject, token);
          });
          projectMileStone.forEach(async (milestone) => {
            await putData(`${MILESTONE_PUT}/${projectId}`,{ milestone }, headerObject, token);
          });
          return newProject
        })
        .then((newProject) => {
          putData(`${PROJECT_PUT}/${projectId}`, { ...newProject }, headerObject, token)
        })
        .then(() => {
          setSetting({ currentProject: +projectId });
          toggleDetails();
          history.push(`/journey`);  
        })
        .catch(error => {
          displayError(error.message);
          setLoading(false);
        });
    }
  };

  const saveDetails = () => {
    if (isBlank) {
      createProject(details.asanaId);
      return false;
    }
    // setLoading(true);
    const optionsBody = {
      subscription: details.serviceTradeTier,
      subscription_end_date: details.subsriptionEnd,
      subscription_start_date: details.subsriptionStart,
      go_live_date: details.goLiveDate,
      licenses: details.licenseCount,
      serviceTradeAccounts: accounts,
      logo: currentCustomer.logo,
      notifications: [
        formatNotification(
          summaryDigestNotification,
          notificationTypes.summaryDigest,
          currentCustomer,
          summaryDigest_notification_active,
        ),
        formatNotification(
          overdueTaskNotification,
          notificationTypes.overdueTask,
          currentCustomer,
          overdueTask_notification_active,
        ),
      ],
      shouldCreateDemoAccount,
      project_disabled: details.project_disabled
    };
    postData(`${ACCOUNT_UPDATE}/${projectId}`, optionsBody, token)
      .then(response => {
        if (response.error && response.statusCode === 401) {
          resetToken();
        }
        return response;
      })
      .then(response => {
        if (response && response.data && response.data.account) {
          toggleDetails();
        } else {
          throw new Error(ERRORS.SERVER);
        }
      })
      .catch(err => {
        displayError(err.message);
        toggleDetails();
      });
    setLoading(false);
  };

  const renderSpinner = () => (
    <Row className={spinnerClass}>
      <Spinner color="primary" />
    </Row>
  );

  const renderTab = () => {
    switch (value) {
      case tabTypes.Account_Edit:
        return (
          <ProjectDetailsForm
            isBlank={isBlank}
            details={details}
            accounts={accounts}
            handleChange={handleChange}
          />
        );
      case tabTypes.Notification_Settings:
        return (
          <NotificationSettings
            summaryDigestNotification={summaryDigestNotification}
            setSummaryDigestNotification={setSummaryDigestNotification}
            overdueTaskNotification={overdueTaskNotification}
            setOverdueTaskNotification={setOverdueTaskNotification}
            overdueTask_notification_active={overdueTask_notification_active}
            setOverdueNotification={setOverdueNotification}
            summaryDigest_notification_active={summaryDigest_notification_active}
            setSummaryNotification={setSummaryNotification}
          />
        );
      case tabTypes.Send_Notification:
        return (
          <SendNotification
            overdueTaskEmails={overdueTaskNotification.body.subscribers}
            summaryDigestEmails={summaryDigestNotification.body.subscribers}
            token={token}
            projectId={projectId}
            companyName={currentCustomer.asanaData.project.name}
          />
        );
      case tabTypes.Account_Health:
        return (
          <AccountHealthSettings
            token={token}
            projectId={projectId}
            companyName={currentCustomer.asanaData.project.name}
            project={currentCustomer}
          />  
        );
      default:
        break;
    }
  };

  const isButtonDisabled = () => {
    if (isBlank) {
      return !details.asanaId;
    }
    return details.project_archived;
  };

  const buttonDisabled = useMemo(() => isButtonDisabled(), [isBlank, details.asanaId, details.project_archived]);

  return (
    <Modal isOpen toggle={toggleDetails} size="lg">
      <ModalHeader toggle={toggleDetails} className={headerClass}>
        Account Edit
      </ModalHeader>
      <Box sx={{ width: '100%' }} style={{ marginLeft: 25 }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="Account Edit Tabs">
          <Tab value={tabTypes.Account_Edit} label="Account Details" />
          <Tab value={tabTypes.Notification_Settings} label="Notification Settings" />
          <Tab value={tabTypes.Send_Notification} label="Send Notification" />
        </Tabs>
      </Box>
      <ModalBody>
        {loading ? renderSpinner() : (
          <Form>
            {renderTab()}
          </Form>
        )}
      </ModalBody>
      {!loading && (
        <ModalFooter className={footerGroup}>
          {value !== tabTypes.Send_Notification && (
            <>
            {isBlank && (
              <FormGroup>
                <Label>
                  <Input
                    type="checkbox"
                    value={shouldCreateDemoAccount}
                    onChange={() => setCreateDemoAccount(shouldCreateDemoAccount => !shouldCreateDemoAccount)}
                  />
                  Create Demo ServiceTrade Account?
                </Label>
              </FormGroup>
            )}
              <Button onClick={saveDetails} disabled={buttonDisabled} color="success">
                Save
              </Button>
            </>
          )}
          <Button onClick={toggleDetails} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

ModalCustomerDetails.propTypes = {
  toggleDetails: PropTypes.func,
};

ModalCustomerDetails.defaultProps = {
  toggleDetails: () => {},
};

export default ModalCustomerDetails;
